import React from 'react'
import { Result, Button, Layout, Icon } from 'antd';
import LayoutGlobal from "../components/layout/index"
import Head from '../components/layout/Head';
const { Content } = Layout;

function ErrorPage() {
    return (
        <LayoutGlobal>
            <Head
                title="404 - Page Not Found"
                description="404 - Page Not Found"
            />
            <div style={{ padding: '135px 0px 20px 0px' }}>
                <Content className="container-app">
                    <div className="content-app">
                        <Result
                            status="404"
                            title="404"
                            subTitle="Sorry, the page you visited does not exist."
                            extra={<Button href="/" type="primary" size={'large'} ghost className="button-tekno button-tekno-black">Home <Icon type="home" /></Button>}
                        />
                    </div>
                </Content>
            </div>
        </LayoutGlobal>
    )
}

export default ErrorPage;